import React, { useState, useEffect } from "react";
import "./FileUploadModal.css";
import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import { AddIcon, CloseIcon, MicIcon, StopIcon } from "../../Assets/assets";
import AudioPlayer from "../AudioPlayer/AudioPlayer";
import useVoiceRecorder from "../../Services/useVoiceRecorder.js";
import { voiceRecorderTimeLimit } from "../../Services/config";
import { setErrorStatus } from "../../Redux/status/action";
import { isValidArray, isValidObject } from "../../Services/validators";
import TextArea from "../TextArea/TextArea";
import InputBox from "../InputBox/InputBox.js";
import NativeSelect from "../NativeSelect/NativeSelect.js";

export default function FileUploadModal(props) {
  const [voiceRecorderControl, setVoiceRecorderControl] = useState(null);
  const { startRecording, saveRecording } = useVoiceRecorder();
  const [counter, setCounter] = useState(null);
  const [formData, setFormData] = useState({
    remarks: "",
    updates: "",
  });

  const [selectedRemarksData, setSelectedRemarksData] = useState({
    selectedKpi: null,
    selectedPenalty: null,
    selectedRemarks: [],
  });

  const [running, setRunning] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState({
    audioBlob: false,
    audioURL: "",
  });

  const [handleDisable, setHandleDisable] = useState(false);

  useEffect(() => {
    if (props.proof?.required) {
      if (props.isUpdate) {
        if (
          voiceRecorderControl === "start" ||
          props.disabled ||
          !isValidString(formData.updates.trim())
        ) {
          return setHandleDisable(true);
        } else {
          return setHandleDisable(false);
        }
      }

      if (props.kpi) {
        if (
          voiceRecorderControl === "start" ||
          (props.disabled &&
            recordedAudio.audioBlob === false &&
            formData.remarks === "") ||
          (props.proof.status === false &&
            props.selectedTask?.requiredRemarks &&
            selectedRemarksData.selectedRemarks.length !==
              props.selectedTask?.requiredRemarks?.length)
        ) {
          return setHandleDisable(true);
        } else {
          return setHandleDisable(false);
        }
      } else if (
        (props.disabled &&
          recordedAudio.audioBlob === false &&
          formData.remarks === "") ||
        voiceRecorderControl === "start"
      ) {
        return setHandleDisable(true);
      } else {
        return setHandleDisable(false);
      }
    }
    if (!props.proof?.required) {
      if (props.kpi) {
        return setHandleDisable(false);
      } else if (formData.remarks === "") {
        return setHandleDisable(true);
      } else if (props.isUpdate && !isValidString(formData.updates.trim())) {
        return setHandleDisable(true);
      } else {
        return setHandleDisable(false);
      }
    }
    // eslint-disable-next-line
  }, [
    props.disabled,
    formData,
    props.isUpdate,
    props.proof?.required,
    voiceRecorderControl,
    props.kpi,
    selectedRemarksData,
    recordedAudio,
  ]);

  useEffect(() => {
    if (props.kpi && props.selectedTask) {
      if (props.proof.status === true && props.selectedTask?.kpi) {
        setSelectedRemarksData({
          ...selectedRemarksData,
          selectedKpi: props.selectedTask.kpi,
        });
      } else if (props.proof.status === false && props.selectedTask?.penalty) {
        setSelectedRemarksData({
          ...selectedRemarksData,
          selectedPenalty: props.selectedTask.penalty,
        });
      }
    }
    // eslint-disable-next-line
  }, [props.kpi, props.proof.status, props.selectedTask]);

  useEffect(() => {
    if (props.clearAudio) {
      setRecordedAudio({
        audioBlob: false,
        audioURL: "",
      });
    }
  }, [props.clearAudio]);

  useEffect(() => {
    if (recordedAudio?.audioURL) {
      setVoiceRecorderControl(null);
    }
  }, [recordedAudio?.audioURL]);

  const voiceRecorderHandler = async () => {
    if (voiceRecorderControl === null || voiceRecorderControl === "cancel") {
      setVoiceRecorderControl("start");
      setCounter(0);
      setRunning(true);

      const res = await startRecording();
      if (res === false) {
        /**
         * voice recorder returns false is microphone is detected or any other issues in recording
         */
        setVoiceRecorderControl(null);
      }
    } else if (voiceRecorderControl === "start") {
      setVoiceRecorderControl("stop");
      setCounter(null);
      setRunning(false);
      const resBlob = await saveRecording();

      setRecordedAudio({
        audioBlob: resBlob,
        audioURL: URL.createObjectURL(resBlob),
      });

      const audioFile = new File([resBlob], `${+new Date()}.mp3`, {
        type: "audio/mp3",
      });

      props.setFile({
        ...props.file,
        audio: audioFile,
      });

      props.setClearAudio && props.setClearAudio(false);
    } else if (voiceRecorderControl === "stop") {
      setVoiceRecorderControl("cancel");
      setRecordedAudio({
        audioBlob: false,
        audioURL: "",
      });
    }
  };

  useEffect(() => {
    let counterTimer;
    if (
      counter !== null &&
      typeof counter === "number" &&
      counter <= voiceRecorderTimeLimit &&
      running
    ) {
      counterTimer = setTimeout(() => {
        setCounter(counter + 1);
      }, 1000);
    } else {
      return () => clearTimeout(counterTimer);
    }
    if (counter === voiceRecorderTimeLimit) {
      setVoiceRecorderControl("stop");
      voiceRecorderHandler();
    }
    // eslint-disable-next-line
  }, [counter]);

  // function checkForMicroPhonePermission() {
  //   navigator.mediaDevices
  //     .getUserMedia({ video: false, audio: true })
  //     .then((stream) => {
  //       window.localStream = stream; // A
  //       window.localAudio.srcObject = stream; // B
  //       window.localAudio.autoplay = true; // C
  //       return true;
  //     })
  //     .catch((err) => {
  //       console.error(`Microphone permission denied: ${err}`);
  //       return false;
  //     });
  // }
  return (
    <Modal
      show={props.showModal}
      onClose={props.closeModal}
      canIgnore={true}
      position=" absolute-center-self-horizontally bottom-0 "
      width="inherit-parent-width"
      boxShadow="none"
      maxWidth="max-width-630px"
      background="background-transparent"
      height="height-fit-to-content">
      <div className="background-white padding-large font-family-RHD-medium box-shadow-default font-color-secondary">
        <div className="text-align-center  font-size-small">
          {props.proof?.required ? "UPLOAD YOUR FILES" : "DESCRIPTION"}
        </div>

        {props.isUpdate && (
          <div>
            <InputBox
              className="inherit-parent-width border-radius-default margin-bottom-large"
              name="updates"
              label="UPDATES"
              type="text"
              autoFocus={true}
              removeResponsive={true}
              autoComplete="off"
              value={formData.updates}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  updates: e.target.value,
                });
              }}
              data-cy="update-field"
              required
            />
          </div>
        )}
        {props.proof?.required && (
          <>
            <div className="font-size-medium padding-top-large padding-bottom-default ">
              Add images
            </div>
            <div className="display-flex">
              {props.tempSrc?.map((data, index) => (
                <div
                  key={index}
                  style={{ height: "56px" }}
                  className="margin-right-large position-relative"
                  data-cy={`uploaded-image-thumbnail-${index}`}>
                  <div
                    onClick={() => {
                      props.RemoveImageOnClick(data.name);
                    }}
                    data-cy="remove-uploaded-image-button"
                    className="background-color-grey width-fit-content border-radius-100-percentage flex-align-items-center flex-justify-content-center position-absolute right-0 opacity-60-percent">
                    <CloseIcon />
                  </div>
                  <img
                    className="border-radius-default"
                    height={56}
                    width={56}
                    src={data && URL.createObjectURL(data)}
                    alt="data"
                  />
                </div>
              ))}

              <form
                className="inherit-parent-width"
                onChange={(event) => {
                  if (event.target.files) {
                    if (
                      ["image/png", "image/jpeg", "image/jpg"].includes(
                        event.target.files[0]?.type
                      ) === false
                    ) {
                      return setErrorStatus({
                        code: "custom",
                        message: "Kindly upload a valid File",
                      });
                    }

                    if (
                      ["image/png", "image/jpeg", "image/jpg"].includes(
                        event.target.files[0]?.type
                      ) === true
                    ) {
                      props.setFile({
                        ...props.file,
                        image: [
                          ...props.file.image,
                          URL.createObjectURL(event.target.files[0]),
                        ],
                      });
                    }
                  }
                }}>
                <div className="height-width-56px margin-bottom-default cursor-pointer">
                  <label>
                    <input
                      type="file"
                      accept=".jpg,.jpeg,.png"
                      name={
                        props.inputName ? props.inputName : "fileUploaderInput"
                      }
                      onClick={(event) => {
                        event.target.value = "";
                      }}
                      className="visibility-hidden position-absolute"
                    />

                    {(!isValidArray(props.tempSrc) ||
                      (isValidArray(props.tempSrc) &&
                        props.tempSrc.length <= 4)) && (
                      <div
                        className="background-color-secondary height-width-56px display-flex flex-justify-content-center flex-align-items-center border-radius-default padding-top"
                        data-cy="file-upload-icon">
                        <AddIcon />
                      </div>
                    )}
                  </label>
                </div>
              </form>
            </div>
          </>
        )}
        {!props.type && (
          <div>
            <TextArea
              name="remarks"
              type="text"
              removeResponsive={true}
              label="REMARKS"
              data-cy="remarks-test-area"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  remarks: e.target.value,
                });
              }}
              className="padding-top-large "
              value={formData.remarks}
            />

            {props.kpi &&
              props.proof.status === false &&
              isValidArray(props.selectedTask?.requiredRemarks) &&
              props.selectedTask?.requiredRemarks?.map((data, index) => (
                <div className="padding-top-large" key={index}>
                  <NativeSelect
                    name={data.title}
                    label={data.title}
                    options={["", ...data.options?.map((data) => data?.value)]}
                    removeResponsive={true}
                    onChange={(event) => {
                      const selectedRemarkIndex = data.options.findIndex(
                        (data) => data.value === event.target.value
                      );

                      setSelectedRemarksData((prevRemarks) => {
                        let newData = prevRemarks;
                        newData.selectedRemarks[index] = data.options?.[selectedRemarkIndex];

                        if (data.options?.[selectedRemarkIndex]?.kpi) {
                          newData = {
                            ...newData,
                            ...(newData.selectedKpi
                              ? {
                                  selectedKpi: {
                                    initial:
                                      newData?.kpi?.initial +
                                      data.options?.[selectedRemarkIndex]?.kpi
                                        ?.initial,
                                    final:
                                      newData.kpi?.final +
                                      data.options?.[selectedRemarkIndex]?.kpi
                                        ?.final,
                                  },
                                }
                              : {
                                  selectedKpi:
                                    data.options?.[selectedRemarkIndex]?.kpi,
                                }),
                          };
                        }

                        if (data.options?.[selectedRemarkIndex]?.penalty) {
                          newData = {
                            ...newData,
                            selectedPenalty:
                              data.options?.[selectedRemarkIndex]?.penalty,
                          };
                        }

                        return newData;
                      });
                    }}
                  />
                </div>
              ))}
          </div>
        )}

        {props.proof?.required && (
          <>
            <div className="font-size-medium padding-top-larger padding-bottom-default ">
              Add voice notes
            </div>

            <div className="flex-justify-content-space-between margin-vertical-large flex-align-items-center">
              {!recordedAudio?.audioURL && (
                <div className="flex-justify-content-space-between inherit-parent-width flex-align-items-center">
                  <div className="display-flex flex-align-items-center">
                    <div
                      className="flex-place-children-page-center  border-radius-50-percentage"
                      onClick={() => voiceRecorderHandler()}
                      data-cy={`${
                        voiceRecorderControl === "start"
                          ? "stop-record-button"
                          : "start-record-button"
                      }`}>
                      {voiceRecorderControl === "start" ? (
                        <StopIcon />
                      ) : (
                        <MicIcon />
                      )}
                    </div>
                    <div className="padding-left-large">
                      {voiceRecorderControl === "start"
                        ? "Recording..."
                        : "Tap the mic to record audio"}
                    </div>
                  </div>
                  {voiceRecorderControl === "start" && (
                    <div style={{ width: 50 }}>
                      00:{counter?.toString().length === 1 ? "0" : ""}
                      {counter}
                    </div>
                  )}
                </div>
              )}
              {recordedAudio?.audioURL && (
                <AudioPlayer
                  className={"flex-basis-80-percentage"}
                  closeOnClick={() => {
                    setRecordedAudio({
                      audioBlob: false,
                      audioURL: "",
                    });
                    props.setClearAudio(true);
                  }}
                  src={
                    typeof recordedAudio.audioURL === "string"
                      ? recordedAudio.audioURL
                      : ""
                  }
                />
              )}
            </div>
          </>
        )}
        <Button
          text="Continue"
          variant={"primary"}
          boxShadow={false}
          type="button"
          className={"margin-top-larger"}
          data-cy={
            handleDisable ? "disabled-continue-button" : "continue-button"
          }
          loading={props.loading}
          disabled={handleDisable}
          onClick={() => {
            props.continueOnClick({
              ...formData,
              ...(isValidArray(selectedRemarksData.selectedRemarks)
                ? { selectedRemarks: selectedRemarksData.selectedRemarks }
                : {}),
              ...(isValidObject(selectedRemarksData.selectedKpi)
                ? { selectedKpi: selectedRemarksData.selectedKpi }
                : {}),
              ...(isValidObject(selectedRemarksData.selectedPenalty)
                ? { selectedPenalty: selectedRemarksData.selectedPenalty }
                : {}),
            });
            setRecordedAudio({
              audioBlob: false,
              audioURL: "",
            });
            setFormData({
              remarks: "",
              updates: "",
            });
            setSelectedRemarksData({
              selectedKpi: null,
              selectedPenalty: null,
              selectedRemarks: [],
            });
          }}
        />
      </div>
    </Modal>
  );
}

function isValidString(str) {
  if (!str) {
    return false;
  }

  if (str.trim() === "") {
    return false;
  }

  if (/[^a-zA-Z0-9\s]/.test(str)) {
    return false;
  }

  return true;
}
