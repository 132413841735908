import { all, put, takeEvery } from "redux-saga/effects";
import store from "../store";
import { setErrorStatus } from "../status/action";
import {
  calculateDistance,
  liveLocationUpdateTime,
  movePerSecond,
} from "../../Utils/constants";
import { isValidObject } from "../../Services/validators";
import { getLocationsByLocationIds } from "../../Services/database";

export const actionTypes = {
  GET_LOCATIONS: "GET_LOCATIONS",
  SET_USER_LOCATION: "SET_USER_LOCATION",
  ADD_USER_DISTANCE: "ADD_USER_DISTANCE",
};

function* setUserLocationWorker(action) {
  try {
    const currentLocation = action.payload.currentLocation;

    if (
      store.getState().locations.currentLocation === null ||
      store.getState().locations.currentLocation?.latitude === null ||
      store.getState().locations.currentLocation?.longitude === null
    ) {
      yield put({
        type: "SET_USER_CURRENT_LOCATION",
        payload: {
          data: {
            ...action.payload.currentLocation,
            timestamp: +new Date(),
          },
        },
      });
    }

    if (
      store.getState().locations.currentLocation?.latitude &&
      store.getState().locations.currentLocation?.longitude
    ) {
      const distance = calculateDistance(
        store.getState().locations.currentLocation,
        currentLocation
      );
      const lastTimeStamp =
        store.getState().locations.currentLocation.timestamp;
      const currentTime = +new Date();
      const timeDifference = (currentTime - lastTimeStamp) / 1000;

      if (
        movePerSecond * timeDifference >= distance ||
        timeDifference >= liveLocationUpdateTime
      ) {
        yield put({
          type: "SET_USER_CURRENT_LOCATION",
          payload: {
            data: {
              ...action.payload.currentLocation,
              timestamp: currentTime,
            },
          },
        });
      }
    }
  } catch (error) {
    console.error(error);
  }
}

function* addUserDistanceWorker() {
  try {
    const _StallLocation =
      store.getState().locations?.data?.[
        store.getState().procedures?.selectedProcedure?.locationId
      ]?._geoloc;
    const StallLocation = {
      latitude: _StallLocation?.lat,
      longitude: _StallLocation?.lng,
    };

    const currentLocation = store.getState().locations?.currentLocation || null;

    if (
      isValidObject(currentLocation) &&
      isValidObject(store.getState().procedures?.selectedProcedure)
    ) {
      yield put({
        type: "SET_USER_DISTANCE",
        payload: {
          distance: calculateDistance(StallLocation, currentLocation),
          // distance: 0,
        },
      });
    } else {
      yield put({
        type: "SET_USER_DISTANCE",
        payload: {
          distance: null,
        },
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* getLocationsWorker(action) {
  try {
    setLocationsLoading(true);
    const response = yield getLocationsByLocationIds(
      action.payload.locationIds
    );
    yield put({
      type: "SET_LOCATIONS_DATA",
      payload: { data: response },
    });
    setLocationsLoading(false);
  } catch (error) {
    setLocationsLoading(false);
    setErrorStatus(error);
  }
}

export default function* locationsWatcher() {
  yield all([
    takeEvery("SET_USER_LOCATION", setUserLocationWorker),
    takeEvery("ADD_USER_DISTANCE", addUserDistanceWorker),
    takeEvery("GET_LOCATIONS", getLocationsWorker),
  ]);
}

function* setLocationsLoading(bool) {
  yield put({
    type: "SET_LOCATIONS_LOADING",
    payload: {
      loading: bool,
    },
  });
}
